import './index.scss';

const COLOR_OPTIONS = [
  {
    borderColor: '#00838f',
    backgroundColor: 'rgba(0, 131, 143,0.2)',
    borderWidth: 3,
  },
  {
    borderColor: '#165b98',
    backgroundColor: 'rgba(22, 91, 152,0.2)',
    borderWidth: 3,
  },
  {
    borderColor: '#aed581',
    backgroundColor: 'rgba(174, 213, 129,0.2)',
    borderWidth: 3,
  },
  {
    borderColor: '#fbc02d',
    backgroundColor: 'rgba(251, 192, 45,0.2)',
    borderWidth: 3,
  },
  {
    borderColor: '#e91e63',
    backgroundColor: 'rgba(233, 30, 99,0.2)',
    borderWidth: 3,
  },
];

const getNewChart = (Chart, ctx, type, displayLegend) => {
  return new Chart(ctx, {
    type,
    options: {
      maintainAspectRatio: false,
      legend: {
        display: displayLegend,
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
          },
        }],
      },
    },
  });
};

export default () => {
  let chart;

  return {
    oncreate(vnode) {
      import(/* webpackChunkName: "chart.js" */ 'chart.js')
        .then(Chart => Chart.default)
        .then((Chart) => {
          const { type = 'bar', displayLegend } = vnode.attrs;
          const canvasEl = vnode.dom.querySelector('canvas');
          chart = getNewChart(Chart, canvasEl, type, displayLegend);
          m.redraw();

          const data = vnode.attrs.data;
          if (chart && data) {
            // update chart data and xAxis if needed
            chart.data = {
              labels: data[0].xLabels,
              datasets: data.map(dataset => ({
                labels: dataset.xLabels,
                data: dataset.yData,
                label: dataset.label || '',
              })),
            };

            // update chart colors and axes and re-render
            if (chart.data.datasets) {
              const colorOptionsCount = COLOR_OPTIONS.length;
              chart.data.datasets.forEach((dataset, idx) => {
                const colorIdx = idx % colorOptionsCount; // repeat colors
                Object.assign(chart.data.datasets[idx], { ...COLOR_OPTIONS[colorIdx] }); // shallow is fine
              });
              // chart.options.scales.xAxes[0].time.unit = timeUnit;

              chart.update();
            }
          }
        });
    },
    onupdate(vnode) {
      const { type = 'bar' } = vnode.attrs;
      if (chart && type !== chart.config.type) {
        chart.config.type = type;
        chart.update();
      }
    },
    view(vnode) {
      const { data, chartDimensions = {} } = vnode.attrs;
      const { width = 400, height = 400 } = chartDimensions;

      return (
        <div>
          <div className="chart-container">
            <canvas
              config={this.setCanvasRef}
              width={width}
              height={height}
              aria-label="Visualization chart" role="img"
            />
          </div>
        </div>
      );
    },
  };
};
